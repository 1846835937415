#estimate-content-block {
    border-radius: 45px 45px 0px 0px;
    text-align: center;
    width: 100%;
    background: white;
    margin-top: -40px;
    overflow-y: auto !important;
    padding-bottom: 60px;
}

.estimate-button-row {
    display: flex;
    justify-content: space-between;
    padding: 7px 0px;
    border: 1px solid #5cb226;
    border-radius: 10px;
    width: 95%;
    margin: 0px auto;
    margin-top: 15px;
}

.product-type-info {
    display: inline-block;
    margin: 0px 5px;
}

.estimate-button-row.active-estimate {
    padding: 7px 0px;
}

.estimate-button-title {
    padding-left: 15px;
}

.estimate-button-title.estimate-active-title {
    width: 90%;
    background: #5cb226;
    padding: 7px 0;
    border-radius: 10px;
}

.estimate-button-row.active-estimate {
    padding: 0;
    border: 0px;
}

.product-details-summary {
    width: 340px;
    display: flex;
    justify-content: right;
}

.product-details-summary.active-summary .product-type-info {
    background: #5cb226;
    padding: 7px;
    border-radius: 10px;
    color: #fff;
}

/* .product-details-summary.active-summary .product-type-info {
    min-width: 150px;
} */

.product-details-summary.active-summary .product-type-info {
    min-width: 82px;
}

.total-info-block {
    margin-top: 10px;
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: space-between;
    width: 95%;
    margin: 0px auto;
    flex-direction: row;
    align-items: flex-start;
}

.total-info-block .product-type-info {
    background: #fff !important;
    color: #504f4f !important;
    border: 1px solid #5cb226;
    font-size: 12px;
    font-weight: 500;
    min-height: 16px;
    background: #5cb226;
    padding: 7px;
    border-radius: 10px;
    color: #fff;
    min-width: 80px;
}

.total-info-inner {
    width: 330px;
    margin-top: 10px;
    display: flex;
    justify-content: right;
}

.product-type-info.empty-type-info {
    height: 35px;
}

.product-name.active-product .light-icon {
    float: left;
}

.active-name {
    float: left;
    margin-left: 5px;
    color: #fff;
}

.terms-text-info {
    width: 75%;
    text-align: left;
    padding-top: 20px;
    color: red;
    margin-top: 50px;
    font-weight: bold;
}

#screenshotImage {
    border: 2px solid #5CB226;
    border-radius: 5px;
    margin-top: 20px;
    padding: 5px;
}

.email-estimate-popup .MuiDialog-paperWidthSm {
    min-width: 600px !important;
}

.email-estimate-popup .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 64px);
    min-height: 350px;
}

.cancelEstimate {
    border: 1px solid #949494 !important;
    color: #000 !important;
    font-size: 12px !important;
    margin-bottom: 10px;
}

.saveEstimate {
    background-color: #5CB226 !important;
    border: 1px solid #5CB226 !important;
    color: #fff !important;
    font-size: 12px !important;
    margin-left: 15px !important;
    margin-bottom: 10px;
}

.cancelShare {
    border: 1px solid #949494 !important;
    color: #000 !important;
    font-size: 12px !important;
    margin-bottom: 10px;
}

.saveShare {
    background-color: #5CB226 !important;
    border: 1px solid #5CB226 !important;
    color: #fff !important;
    font-size: 12px !important;
    margin-left: 15px !important;
    margin-bottom: 10px;
}

.email-estimate-input {
    border: 0px;
    border-bottom: 1px solid #949494;
    width: 100%;
}

.email-estimate-input:focus {
    outline: none;
}

.estimate-title h2 {
    font-weight: bold !important;
    font-size: 20px;
}

.estimate-title {
    padding-bottom: 0;
    text-align: center;
}

.attechment-text {
    margin-top: 10px;
    margin-bottom: -10px;
    color: #949494 !important;
}


@media screen and (max-width: 768px) {
    .terms-text-info {
        width: 100% !important;
        order: 2;
        padding-top: 0;
    }

    .total-info-block {
        flex-wrap: wrap;
        justify-content: right;
    }

    .email-estimate-popup .MuiDialog-paperWidthSm {
        min-width: 95% !important;
        max-width: 95% !important;
    }

}

@media screen and (max-width: 450px) {

    .product-details-summary {
        width: 100%;
    }

    .estimate-button-row.active-estimate {
        flex-wrap: wrap;
    }

    .product-details-summary {
        width: 100%;
    }

    .estimate-button-title.estimate-active-title {
        width: 100%;
    }

    .product-details-summary.active-summary {
        margin-top: 10px;
    }

    .product-name {
        font-size: 12px;
    }

    .terms-text-info {
        font-size: 13px;
    }
}

@media screen and (max-width: 360px) {
    .product-type-info {
        font-size: 12px !important;
    }

    .product-type-info {
        font-size: 12px !important;
    }
}