.uploaded-image {
  background-color: white;
  padding: 20px;
  margin-top: -50px;
  border-radius: 60px 60px;
  display: flex;
  justify-content: center;
}

/* .upload-block-image img {
  margin-top: 40px;
} */

.upload-block-image {
  border-radius: 50px 50px 0px 0px;
  text-align: center;
  width: 100%;
  background: white;
  margin-top: -40px;
  /* overflow-y: auto !important; */
  /* max-height: 90vh; */
  /* padding-bottom: 60px; */
  /* min-height: 90vh; */
  max-height: calc(100vh - 95px);
  min-height: calc(100vh - 95px);
}

.upload-block-image-cropper {
  border-radius: 50px 50px 0px 0px;
  text-align: center;
  width: 100%;
  background: white;
  margin-top: -40px;
  /* overflow-y: auto !important; */
  max-height: 86%;
  /* padding-bottom: 60px; */
  /* min-height: 86%; */
  min-height: 150px;
  overflow: hidden !important;
}

.upload-block-image-cropper .cropper-drag-box {
  /* max-height: 84vh; */
  /* padding-bottom: 60px; */
  min-height: 84vh;
  margin-top: 20px;
}

.cropper-canvas img {
  max-height: 87vh !important;
}

.cropper-container {
  /* max-height: 90vh; */
  height: auto;
  /* overflow: scroll; */
}

.header {
  /* min-height: 250px; */
  text-align: center;
  padding-top: 25px;
  color: #fff;
}

.header h5 {
  font-size: 18px;
  margin-bottom: 30px;
}

.back-button {
  position: absolute;
  top: 38px;
  left: 5%;
}

.back-button button {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
}

.back-button button img {
  margin-right: 10px;
}

.save-button {
  position: absolute;
  top: 38px;
  right: 5%;
}

.save-button button {
  background-color: transparent;
  border: 0px;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
}

.popup-content.delete-popup-content {
  left: 0px !important;
  bottom: 0px !important;
  width: 100% !important;
  height: 200px;
  position: fixed !important;
  border-radius: 40px 40px 0px 0px !important;
  padding-bottom: 70px;
  margin-bottom: 0;
  z-index: 999999 !important;
}

.delete-popup-button-block {
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

/* .delete-popup-button-block {
  display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
} */



.delete-popup-button-block p {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-top: 0;
}

.delete-btn-block {
  display: flex;
  justify-content: space-around;
  width: 100%;
  /* margin-top: 30px; */
}

.delete-popup-button-block.crop-delete-popup {
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.delete-popup-button-block.rename-popup {
  display: flex;
  /* height: 100%; */
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.select-collapse-button {
  padding: 15px 10px;
}

.recommended-button {
  padding: 15px 20px;
  background: #5cb226;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
}

.delete-btn-block-inner {
  padding: 0px 80px;
}

.delete-btn-block button {
  padding: 7px 15px;
  width: 130px;
  background: transparent;
  border: 1px solid #bfbcbc;
  border-radius: 5px;
  margin: 5px;
  position: relative;
  right: 10px;
}

.delete-popup-button-block.rename-popup .delete-btn-block button {
  width: 80px;
}

.delete-block {
  background-color: #5CB226 !important;
  color: #fff;
  padding-left: 12px !important;
}

.delete-icon-block {
  width: 42px;
  height: 42px;
  background: #5cb226;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  margin-bottom: 10px;
}

.edit-icon-block {
  width: 42px;
  height: 42px;
  background: #5cb226;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  overflow: hidden;
}

.edit-icon-block img {
  width: 25px;
}

.hamburger-block {
  width: 165px;
  /* background: red; */
  float: right;
  position: fixed;
  padding-left: 35px;
  bottom: 0;
  right: 0;
}

.hamburger-inner {
  /* background: green; */
  border-radius: 50%;
}

.edit-icon-block.custom-block3 {
  position: relative;
  left: -1px;
  top: -11px;
}

.custom-block2 {
  position: relative;
  left: 31px;
  top: -7px;
}



.custom-block1 {
  position: relative;
  left: 82px;
  top: 18px;
}

.hamburger-bar-icon {
  position: fixed;
  bottom: 130px;
  right: 40px;
  display: flex;
}

/* .hamburger-bar-icon img {
  width: 60px;
} */

/* .upload-block-image img {
  margin-top: 30px;
} */

.alert-icon-info {
  display: flex;
  margin: 25px 25px 0px 25px;
}

.alert-icon {
  margin-right: 10px;
}

.crop-icon-block {
  background: #fff;
  width: 100% !important;
  display: flex;
  justify-content: center;
  height: 50px;
  align-items: center;
  padding: 30px 0px;
  /* padding-bottom: 55px !important; */
}

.crop-section-block {
  width: 200px;
  margin-top: 140px;
  /* margin: 0px auto; */

}


.crop-icon-block.custom-block4.custom-icon-postion {
  display: flex;
  justify-content: center;
  padding: 0;
  position: absolute;
  /* bottom: -55px; */
  left: 50%;
  right: 50%;
  transform: translate(-50%, 10px);
  padding: 20px;
  width: 50px !important;
  background: #5cb226;
  border-radius: 50%;
}

.popup-custom-icon-block {
  display: inline;
}

.crop-icon-block.custom-block4.custom-icon-postion svg {
  font-size: 35px;
  position: relative;
  /* top: 45px; */
  color: #fff;
}

.popup-content.delete-popup-content-content {
  position: fixed !important;
  bottom: 50px !important;
  left: 0px !important;
  width: 100% !important;
}



.popup-content.delete-popup-content-content-content {
  position: fixed !important;
  width: 100% !important;
  left: 0px !important;
  bottom: 0px !important;
  top: 70% !important;
  border-radius: 30px 30px 0px 0px !important;
  padding: 5px 10px;

}


/*Setting Basic Dimensions to give 
    gallery view */
.container {
  margin: 0 auto;
  width: 90%;
}

.main_view {
  width: 80%;
  height: 25rem;
}

.main_view img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.side_view {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.side_view img.active {
  height: 65px;
}

.side_view img {
  width: 60px;
  height: 50px;
  object-fit: cover;
  cursor: pointer;
  margin: 0.5rem;
}


.side_view {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  box-shadow: 0px 0px 10px 0px #00000082;
  padding: 15px 0px;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background-color: #fff;
  align-items: center;
  z-index: 99999 !important;
}

.side_view img {
  border: 4px solid #5cb226;
  border-radius: 10px;
}

.popup-custom-icon-block {
  /* margin-left: auto; */
  position: absolute;
  right: 110px;
  bottom: 20px;
}

.close-icon-block {
  box-shadow: 1px 2px 6px 0px #00000042;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5cb226;
  /* z-index: 999999999999; */
}

.hamburger-block.crop-icon-block {
  background-color: transparent;
  bottom: 100px;
}

.input-custom-block {
  width: 300px;
  margin: 0px auto;
}

.input-custom-block input {
  padding: 10px;
  width: 92%;
  margin-top: 5px;
}

.delete-btn-block-inner {
  width: 100%;
  justify-content: center;
  display: flex;
}

.delete-popup-button-block h5 {
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  align-items: center;
}


@media screen and (max-width: 768px) {
  .upload-block-image {
    border-radius: 50px 50px 0px 0px;
  }

  .input-custom-block input {
    width: 100%;
  }

  .product-type-info {
    font-size: 14px;
  }

  .product-name {
    font-size: 14px;
  }

  .save-button {
    top: 20px;
  }

  .uploaded-pdf .upload-block-image img {
    width: 80% !important;
  }

  /* .edit-header {
    width: 80%;
  } */

  .back-button button {
    font-size: 13px;
  }

  /* .edit-header svg {
    bottom: 60px;
  } */
  .hamburger-bar-icon {
    right: 20px;
    bottom: 125px;
  }

  .custom-block1 {
    top: 34px;
    left: 94px;
  }

  .custom-block2 {
    position: relative;
    left: 65px !important;
    top: 15px;
  }

  .edit-icon-block.custom-block3 {
    position: relative;
    left: 48px !important;
    top: 6px !important;
  }

  .edit-icon-block.custom-block3 svg {
    font-size: 17px;
  }

  .delete-btn-block {
    margin-top: 10px;
  }

  .delete-btn-block-inner {
    width: 300px;
    justify-content: center;

  }

  .sidebarClassName11 {
    top: 0px !important;
    max-width: 500px;
    position: absolute !important;
    /* overflow-y: auto !important; */
    max-height: 93.6vh;
  }



  .hamburger-block.crop-icon-block {
    bottom: 110px;
  }

  .delete-icon-block.contact-details-icon.custom-block2 {
    left: 90px !important;
    top: 0px !important;
  }

  .edit-icon-block.custom-block3.mail-icon-block {
    left: 55px !important;
    top: -15px !important;
  }

}

.estimate-block button {
  background: transparent;
  border: 0px;
  color: #fff;
}

.estimate-block {
  position: absolute;
  right: 5%;
  top: 35px;
}

.edit-header h6 {
  font-size: 13px;
}

.grid-block-img {
  width: 100% !important;
}

.grid-block {
  position: absolute;
  width: 100%;
  left: 0;
  display: none;
}

.grid-block-icon-click {
  position: absolute;
  width: 100%;
  left: 0;
  display: block;
}


.grid-block-img {
  width: 100% !important;
  height: 90vh;
  object-fit: cover;
}

/* ==================css for image center================= */

.react-transform-wrapper.transform-component-module_wrapper__SPB86 {
  margin: 0px auto !important;

}

.grid-block-img {
  width: 100% !important;
  height: 100vh;
  object-fit: cover;
  z-index: 2;
  position: absolute;
  border-radius: 70px 70px 0px 0px !important;
  left: 0;
}

.react-transform-wrapper.transform-component-module_wrapper__SPB86 {
  position: relative;
  top: 80px;
}

.slide-view-custom-circle {
  position: absolute;
  width: 5px;
  height: 5px;
  background: green;
  border-radius: 50%;
  top: 50%;
  left: 50%;
}

.slide-view-image-block {
  position: relative;
}

.upload-block-image {
  border-radius: 50px 50px 0px 0px;
  text-align: center;
  width: 100%;
  background: white;
  /* margin-top: -40px; */
  /* overflow-y: auto !important; */
  /* max-height: 100vh; */
  /* padding-bottom: 60px; */
  /* min-height: 100vh; */

}

/* 29 May */
.cropper-wrapper-inner {
  width: 500px;
  height: auto;
  margin: auto;
}


/* .uploaded-pdf {
  background: #5CB226;
} */

.cropper-modal {
  background-color: #21261e;
  opacity: 0.5;
}

/* .cropper-container .cropper-canvas {
  background-color: red
} */


.uploaded-pdf .upload-block-image img {
  /* max-height: 65vh; */
  /* margin-top: 10px; */
}

.uploaded-pdf {
  /* max-height: 87vh; */
  max-height: 100vh;

}

.page-content-custom-block {
  overflow: hidden;
}

.cropper-container img {
  margin-top: 20px;
}


@media screen and (max-width: 400px) {

  .delete-btn-block-inner {
    width: 100%;
  }

  .crop-icon-block.custom-block4.custom-icon-postion {
    overflow-y: hidden;
  }

  .input-custom-block {
    width: 100%;
  }

  .total-info-inner {
    justify-content: right;
  }

}

.uploaded-pdf-custom-area {
  position: relative;
}

.row {
  margin: 0px;
}

.close-icon {
  margin-left: 260px;
}


.sidebar-open .close-icon {
  background: #fff;
  border: 0px;
  position: absolute;
  top: 10px;
  right: 0px;
  width: 100%;
}

.sidebar-open .close-icon img {
  width: 17px;
  float: right;
  margin-right: 5px;
  margin-top: 5px;
}

.overlayClassName11 {
  max-height: calc(100vh - 95px);
}

.sidebarClassName11 {
  top: 0px !important;
  max-width: 500px;
  position: absolute !important;
  max-height: 100vh;
  /* overflow-y: auto !important; */
}

.rootClassName11 {
  top: 95px !important;
  max-width: 500px;
  height: 100% !important;
  width: min-content;
  /* height: 90%; */
}

.overlayClassName11 {
  top: 95px !important;
  max-width: 500px;
  width: min-content;
}

.contentClassName11 {
  /* top: 95px !important; */
  max-width: 500px;
  max-height: calc(100vh - 95px);

}


.select-lighting-block-area .select-lighting-block-area .lightheader {
  margin-top: 0px !important;
}

.sidebar-open.sidebar-block-area {
  margin-left: -30px;
}

.sidebar-block-area .lightheader.select-light-header {
  margin-top: 0px !important;
}

.sidebar-block-area .select-lighting-block {
  top: 60px !important;
}

/* .uploaded-pdf-custom-area .upload-block-image {
  display: flex;
  justify-content: right;

}

.uploaded-pdf-custom-area .upload-block-image {
  margin-right: 20%;
}

.uploaded-pdf-custom-area .upload-block-image div:nth-child(1) {
  width: 65%;
} */

.uploaded-pdf-custom-area .upload-block-image img {
  margin-left: 35%;
  /* margin-left: 25%; */
}

@media screen and (max-width: 1600px) {
  .sidebarClassName11 {
    width: 500px !important;
  }

  .uploaded-pdf-custom-area .upload-block-image img {
    margin-left: 29%;
  }
}


@media screen and (min-width: 1601px) and (max-width: 2000px) {
  .uploaded-pdf-custom-area .upload-block-image img {
    margin-left: 36%;
  }
}

@media screen and (max-width: 1300px) {
  .sidebarClassName11 {

    max-width: 400px;
  }

  .rootClassName11 {

    max-width: 400px;
  }

  .overlayClassName11 {

    max-width: 400px;
  }

  .contentClassName11 {

    max-width: 400px;
  }






  .uploaded-pdf-custom-area .upload-block-image img {
    margin-left: 33%;
  }

  .selected-light-block {
    width: 95% !important;
    margin: 0px auto;
  }

  .selected-lighting {
    justify-content: center;
  }
}

@media screen and (max-width: 992px) {
  .sidebarClassName11 {

    max-width: 250px;
  }

  .rootClassName11 {

    max-width: 250px;
  }

  .overlayClassName11 {

    max-width: 250px;
  }

  .contentClassName11 {

    max-width: 250px;
  }

  .uploaded-pdf-custom-area .upload-block-image img {
    margin-left: 32%;
  }
}



.uploaded-pdf-section-inner {
  padding: 0px !important;
}

.rotate-handle {
  margin-left: 0px !important;
}

.six-circle-container.react-draggable.react-resizable .actions {
  top: 30px;
}

.six-circle-container.react-draggable.react-resizable .vertical-horizontal-resize {
  margin-top: 37px !important;
}

.normal-img-width {
  height: auto;
  width: 400px;
  overflow: hidden;
  /* text-align: center; */
}

.onclick-img-width {
  height: auto;
  width: 700px;
  overflow: hidden;
  /* text-align: center; */
}

.zoom-button {
  position: relative;
  z-index: 9999;
}

.save-button.save-button-block {
  display: flex;
  align-items: center;
}

.zoom-in-icon {
  color: #fff;
  width: 25px;
  margin-right: 10px;
}

.zoom-out-icon {
  color: #fff;
  width: 25px;
  margin-right: 10px;
}

.save-button.save-button-block button {
  margin-top: -4px;
}

.__floater.__floater__open {
  z-index: 999999999999 !important;
}

@media screen and (max-width: 992px) {
  .react-joyride__tooltip {
    width: 290px !important;
    left: 5px !important;
  }
}

.file-download-icon {
  color: #fff;
}

.grid-block-img {
  display: none;
}

.bg-grid-img {
  background-image: url(../../assets/img/gridlines.svg);
  background-size: contain;
  height: max-content;
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  height: 100%;
  border-radius: 50px 50px 0px 0px;

}

.bg-grid-block-area {
  padding-bottom: 180px !important;
}

.bg-grid-block-area img {
  margin-top: 10px;
}

.bg-grid-block-area img {
  margin-bottom: 10px;
}

.upload-block-image .zoomIn-upload-block-img .bg-grid-block-area img {
  margin-bottom: 150px;
}

.bg-grid-block-area {
  position: relative;
}

/* .sidebarClassName11.sidebar-with-zoomIn { 
  top: 0px !important;
} */


.delete-icon-block.contact-details-icon.custom-block2 {
  left: 67px;
  top: -20px;
}

.edit-icon-block.custom-block3.mail-icon-block {
  left: 12px;
  top: -11px;
}

.share-title h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.bg-grid-block-area {
  padding-bottom: 0px;
}



.sidebarClassName11.sidebar-with-zoomIn {
  position: fixed !important;
  top: 0px !important;
}

.popup-content {
  z-index: 999999 !important;
}

.popup-overlay.delete-popup-overlay {
  z-index: 999999 !important;
}




/* ============================ 26-07-2024 css changes ========================== */

.react-joyride__overlay {
  z-index: 999999 !important;
}

@media screen and (max-width: 768px) {
  .rotate-icon-green img.rotate-handle {
    width: 21px !important;
  }
}


@media screen and (max-width: 550px) {
  .cropper-container {
    width: 85% !important;
    max-width: 85% !important;
    height: auto;
  }

}

@media screen and (max-width:768px) {
  .file-upload-block.row {
    padding-bottom: 60px;
  }
}